var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "talentreuse-container" }, [
    _c("div", { staticClass: "talentreuse" }, [
      _vm._m(0),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "talentreuse-list",
        },
        [
          _c("div", { staticClass: "talentreuse-list-title" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.talentReuseTitle) + " :\n        "
            ),
            _c("span", { staticClass: "green-write" }, [
              _vm._v(_vm._s(_vm.data.total ? _vm.data.total : 0)),
            ]),
          ]),
          _vm.data.total
            ? [
                _c(
                  "ul",
                  { staticClass: "talentreuse-list-ul" },
                  [
                    _vm.key == "ViewCandidates"
                      ? [
                          _vm.candidateViewType == 0 && _vm.isMyCandidate
                            ? [
                                _vm._m(1),
                                _vm._l(_vm.list, function (item, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: index,
                                      staticClass: "list-li viewcandidate",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "list-li-item time" },
                                        [
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                item.operationTime
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "list-li-item username",
                                        },
                                        [
                                          _c("span", {
                                            attrs: { title: item.userName },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.userName
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "list-li-item resume" },
                                        [
                                          _c(
                                            "a",
                                            _vm._b(
                                              {
                                                staticClass: "list-li-item-a",
                                                attrs: {
                                                  target: "_blank",
                                                  title: item.candidateInfo,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.candidateInfo
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleCandidate(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              "a",
                                              {
                                                href:
                                                  "/#/candidateDetail/" +
                                                  item.candidateId,
                                              },
                                              false
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                              ]
                            : _vm._e(),
                          _vm.candidateViewType == 1 && _vm.isMyCandidate
                            ? [
                                _vm._m(2),
                                _vm._l(_vm.list, function (item, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: index,
                                      staticClass: "list-li viewcandidate",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "list-li-item time" },
                                        [
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                item.operationTime
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "list-li-item username",
                                        },
                                        [
                                          _c("span", {
                                            attrs: { title: item.userName },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.userName
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "list-li-item resume" },
                                        [
                                          _c(
                                            "a",
                                            _vm._b(
                                              {
                                                staticClass: "list-li-item-a",
                                                attrs: {
                                                  target: "_blank",
                                                  title: item.candidateInfo,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.candidateInfo
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleCandidate(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              "a",
                                              {
                                                href:
                                                  "/#/candidateDetail/" +
                                                  item.candidateId,
                                              },
                                              false
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                              ]
                            : _vm._e(),
                          _vm.candidateViewType == 2 && _vm.isMyCandidate
                            ? [
                                _vm._m(3),
                                _vm._l(_vm.list, function (item, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: index,
                                      staticClass: "list-li viewcandidate",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "list-li-item time" },
                                        [
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                item.operationTime
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "list-li-item username",
                                        },
                                        [
                                          _c("span", {
                                            attrs: { title: item.userName },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.userName
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "list-li-item resume" },
                                        [
                                          _c(
                                            "a",
                                            _vm._b(
                                              {
                                                staticClass: "list-li-item-a",
                                                attrs: {
                                                  target: "_blank",
                                                  title: item.candidateInfo,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.candidateInfo
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleCandidate(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              "a",
                                              {
                                                href:
                                                  "/#/candidateDetail/" +
                                                  item.candidateId,
                                              },
                                              false
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                              ]
                            : _vm._e(),
                          _vm.candidateViewType == 0 && !_vm.isMyCandidate
                            ? [
                                _vm._m(4),
                                _vm._l(_vm.list, function (item, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: index,
                                      staticClass: "list-li viewcandidate",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "list-li-item time" },
                                        [
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                item.operationTime
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "list-li-item username",
                                        },
                                        [
                                          _c("span", {
                                            attrs: { title: item.userName },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.userName
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "list-li-item resume" },
                                        [
                                          _c(
                                            "a",
                                            _vm._b(
                                              {
                                                staticClass: "list-li-item-a",
                                                attrs: {
                                                  target: "_blank",
                                                  title: item.candidateInfo,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.candidateInfo
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleCandidate(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              "a",
                                              {
                                                href:
                                                  "/#/candidateDetail/" +
                                                  item.candidateId,
                                              },
                                              false
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                              ]
                            : _vm._e(),
                          _vm.candidateViewType == 1 && !_vm.isMyCandidate
                            ? [
                                _vm._m(5),
                                _vm._l(_vm.list, function (item, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: index,
                                      staticClass: "list-li viewcandidate",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "list-li-item time" },
                                        [
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                item.operationTime
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "list-li-item username",
                                        },
                                        [
                                          _c("span", {
                                            attrs: { title: item.userName },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.userName
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "list-li-item resume" },
                                        [
                                          _c(
                                            "a",
                                            _vm._b(
                                              {
                                                staticClass: "list-li-item-a",
                                                attrs: {
                                                  target: "_blank",
                                                  title: item.candidateInfo,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.candidateInfo
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleCandidate(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              "a",
                                              {
                                                href:
                                                  "/#/candidateDetail/" +
                                                  item.candidateId,
                                              },
                                              false
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                              ]
                            : _vm._e(),
                          _vm.candidateViewType == 2 && !_vm.isMyCandidate
                            ? [
                                _vm._m(6),
                                _vm._l(_vm.list, function (item, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: index,
                                      staticClass: "list-li viewcandidate",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "list-li-item time" },
                                        [
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                item.operationTime
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "list-li-item username",
                                        },
                                        [
                                          _c("span", {
                                            attrs: { title: item.userName },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.userName
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "list-li-item resume" },
                                        [
                                          _c(
                                            "a",
                                            _vm._b(
                                              {
                                                staticClass: "list-li-item-a",
                                                attrs: {
                                                  target: "_blank",
                                                  title: item.candidateInfo,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.candidateInfo
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleCandidate(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              "a",
                                              {
                                                href:
                                                  "/#/candidateDetail/" +
                                                  item.candidateId,
                                              },
                                              false
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                              ]
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm.key == "RecommendCandidates"
                      ? [
                          _vm.recommendType == 0 && _vm.isMyCandidate
                            ? [
                                _c(
                                  "li",
                                  { staticClass: "list-li recommendcandidate" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "list-li-item time" },
                                      [_vm._v("推荐时间")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "list-li-item username",
                                        class: {
                                          "username-long": !_vm.isCFUser,
                                        },
                                      },
                                      [_vm._v("推荐人")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "list-li-item resume",
                                        class: { "resume-long": !_vm.isCFUser },
                                      },
                                      [_vm._v("推荐人才")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "list-li-item recommendation",
                                        class: {
                                          "recommendation-long": !_vm.isCFUser,
                                        },
                                      },
                                      [_vm._v("推荐公司/职位")]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "list-li-item status" },
                                      [_vm._v("最新状态")]
                                    ),
                                    _vm.isCFUser
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "list-li-item belongername",
                                          },
                                          [_vm._v("归属权")]
                                        )
                                      : _vm._e(),
                                    _vm.isCFUser
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "list-li-item proportion",
                                          },
                                          [_vm._v("我的分成比例")]
                                        )
                                      : _vm._e(),
                                    _vm.isCFUser
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "list-li-item realProportion",
                                          },
                                          [_vm._v("实际分成值")]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._l(_vm.list, function (item, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: index,
                                      staticClass: "list-li recommendcandidate",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "list-li-item time" },
                                        [
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                item.operationTime
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "list-li-item username",
                                          class: {
                                            "username-long": !_vm.isCFUser,
                                          },
                                        },
                                        [
                                          _c("span", {
                                            attrs: { title: item.userName },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.userName
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "list-li-item resume",
                                          class: {
                                            "resume-long": !_vm.isCFUser,
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            _vm._b(
                                              {
                                                staticClass: "list-li-item-a",
                                                attrs: {
                                                  target: "_blank",
                                                  title: item.candidateInfo,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.candidateInfo
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleCandidate(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              "a",
                                              {
                                                href:
                                                  "/#/candidateDetail/" +
                                                  item.candidateId,
                                              },
                                              false
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "list-li-item recommendation",
                                          class: {
                                            "recommendation-long":
                                              !_vm.isCFUser,
                                          },
                                        },
                                        [
                                          _c("span", {
                                            attrs: {
                                              title: item.recommendationInfo,
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.recommendationInfo
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "list-li-item status" },
                                        [
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(item.status),
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm.isCFUser
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "list-li-item belongername",
                                            },
                                            [
                                              _c("span", {
                                                attrs: {
                                                  title: item.belongerName,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.belongerName
                                                  ),
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isCFUser
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "list-li-item proportion",
                                            },
                                            [
                                              _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.proportion
                                                  ),
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isCFUser
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "list-li-item realProportion",
                                            },
                                            [
                                              _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.realProportion
                                                  ),
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                }),
                              ]
                            : _vm._e(),
                          _vm.recommendType == 1 && _vm.isMyCandidate
                            ? [
                                _c(
                                  "li",
                                  { staticClass: "list-li recommendcandidate" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "list-li-item time" },
                                      [_vm._v("offer时间")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "list-li-item username",
                                        class: {
                                          "username-long": !_vm.isCFUser,
                                        },
                                      },
                                      [_vm._v("推荐人")]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "list-li-item time" },
                                      [_vm._v("推荐时间")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "list-li-item resume",
                                        class: { "resume-long": !_vm.isCFUser },
                                      },
                                      [_vm._v("offer人才")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "list-li-item recommendation",
                                        class: {
                                          "recommendation-long": !_vm.isCFUser,
                                        },
                                      },
                                      [_vm._v("offer公司/职位")]
                                    ),
                                    _vm.isCFUser
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "list-li-item belongername",
                                          },
                                          [_vm._v("归属权")]
                                        )
                                      : _vm._e(),
                                    _vm.isCFUser
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "list-li-item proportion",
                                          },
                                          [_vm._v("我的分成比例")]
                                        )
                                      : _vm._e(),
                                    _vm.isCFUser
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "list-li-item realProportion",
                                          },
                                          [_vm._v("实际分成值")]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._l(_vm.list, function (item, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: index,
                                      staticClass: "list-li recommendcandidate",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "list-li-item time" },
                                        [
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                item.flowTime
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "list-li-item username",
                                          class: {
                                            "username-long": !_vm.isCFUser,
                                          },
                                        },
                                        [
                                          _c("span", {
                                            attrs: { title: item.userName },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.userName
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "list-li-item time" },
                                        [
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                item.operationTime
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "list-li-item resume",
                                          class: {
                                            "resume-long": !_vm.isCFUser,
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            _vm._b(
                                              {
                                                staticClass: "list-li-item-a",
                                                attrs: {
                                                  target: "_blank",
                                                  title: item.candidateInfo,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.candidateInfo
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleCandidate(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              "a",
                                              {
                                                href:
                                                  "/#/candidateDetail/" +
                                                  item.candidateId,
                                              },
                                              false
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "list-li-item recommendation",
                                          class: {
                                            "recommendation-long":
                                              !_vm.isCFUser,
                                          },
                                        },
                                        [
                                          _c("span", {
                                            attrs: {
                                              title: item.recommendationInfo,
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.recommendationInfo
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm.isCFUser
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "list-li-item belongername",
                                            },
                                            [
                                              _c("span", {
                                                attrs: {
                                                  title: item.belongerName,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.belongerName
                                                  ),
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isCFUser
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "list-li-item proportion",
                                            },
                                            [
                                              _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.proportion
                                                  ),
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isCFUser
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "list-li-item realProportion",
                                            },
                                            [
                                              _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.realProportion
                                                  ),
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                }),
                              ]
                            : _vm._e(),
                          _vm.recommendType == 0 && !_vm.isMyCandidate
                            ? [
                                _c(
                                  "li",
                                  { staticClass: "list-li recommendcandidate" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "list-li-item time" },
                                      [_vm._v("推荐时间")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "list-li-item username",
                                        class: {
                                          "username-long": !_vm.isCFUser,
                                        },
                                      },
                                      [_vm._v("简历拥有人")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "list-li-item resume",
                                        class: { "resume-long": !_vm.isCFUser },
                                      },
                                      [_vm._v("推荐人才")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "list-li-item recommendation",
                                        class: {
                                          "recommendation-long": !_vm.isCFUser,
                                        },
                                      },
                                      [_vm._v("推荐公司/职位")]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "list-li-item status" },
                                      [_vm._v("最新状态")]
                                    ),
                                    _vm.isCFUser
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "list-li-item belongername",
                                          },
                                          [_vm._v("归属权")]
                                        )
                                      : _vm._e(),
                                    _vm.isCFUser
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "list-li-item proportion",
                                          },
                                          [_vm._v("我的分成比例")]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._l(_vm.list, function (item, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: index,
                                      staticClass: "list-li recommendcandidate",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "list-li-item time" },
                                        [
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                item.operationTime
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "list-li-item username",
                                          class: {
                                            "username-long": !_vm.isCFUser,
                                          },
                                        },
                                        [
                                          _c("span", {
                                            attrs: { title: item.userName },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.userName
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "list-li-item resume",
                                          class: {
                                            "resume-long": !_vm.isCFUser,
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            _vm._b(
                                              {
                                                staticClass: "list-li-item-a",
                                                attrs: {
                                                  target: "_blank",
                                                  title: item.candidateInfo,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.candidateInfo
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleCandidate(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              "a",
                                              {
                                                href:
                                                  "/#/candidateDetail/" +
                                                  item.candidateId,
                                              },
                                              false
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "list-li-item recommendation",
                                          class: {
                                            "recommendation-long":
                                              !_vm.isCFUser,
                                          },
                                        },
                                        [
                                          _c("span", {
                                            attrs: {
                                              title: item.recommendationInfo,
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.recommendationInfo
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "list-li-item status" },
                                        [
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(item.status),
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm.isCFUser
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "list-li-item belongername",
                                            },
                                            [
                                              _c("span", {
                                                attrs: {
                                                  title: item.belongerName,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.belongerName
                                                  ),
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isCFUser
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "list-li-item proportion",
                                            },
                                            [
                                              _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.proportion
                                                  ),
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                }),
                              ]
                            : _vm._e(),
                          _vm.recommendType == 1 && !_vm.isMyCandidate
                            ? [
                                _c(
                                  "li",
                                  { staticClass: "list-li recommendcandidate" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "list-li-item time" },
                                      [_vm._v("offer时间")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "list-li-item username",
                                        class: {
                                          "username-long": !_vm.isCFUser,
                                        },
                                      },
                                      [_vm._v("简历拥有人")]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "list-li-item time" },
                                      [_vm._v("推荐时间")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "list-li-item resume",
                                        class: { "resume-long": !_vm.isCFUser },
                                      },
                                      [_vm._v("offer人才")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "list-li-item recommendation",
                                        class: {
                                          "recommendation-long": !_vm.isCFUser,
                                        },
                                      },
                                      [_vm._v("offer公司/职位")]
                                    ),
                                    _vm.isCFUser
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "list-li-item belongername",
                                          },
                                          [_vm._v("归属权")]
                                        )
                                      : _vm._e(),
                                    _vm.isCFUser
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "list-li-item proportion",
                                          },
                                          [_vm._v("我的分成比例")]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._l(_vm.list, function (item, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: index,
                                      staticClass: "list-li recommendcandidate",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "list-li-item time" },
                                        [
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                item.flowTime
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "list-li-item username",
                                          class: {
                                            "username-long": !_vm.isCFUser,
                                          },
                                        },
                                        [
                                          _c("span", {
                                            attrs: { title: item.userName },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.userName
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "list-li-item time" },
                                        [
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                item.operationTime
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "list-li-item resume",
                                          class: {
                                            "resume-long": !_vm.isCFUser,
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            _vm._b(
                                              {
                                                staticClass: "list-li-item-a",
                                                attrs: {
                                                  target: "_blank",
                                                  title: item.candidateInfo,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.candidateInfo
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleCandidate(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              "a",
                                              {
                                                href:
                                                  "/#/candidateDetail/" +
                                                  item.candidateId,
                                              },
                                              false
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "list-li-item recommendation",
                                          class: {
                                            "recommendation-long":
                                              !_vm.isCFUser,
                                          },
                                        },
                                        [
                                          _c("span", {
                                            attrs: {
                                              title: item.recommendationInfo,
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.recommendationInfo
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm.isCFUser
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "list-li-item belongername",
                                            },
                                            [
                                              _c("span", {
                                                attrs: {
                                                  title: item.belongerName,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.belongerName
                                                  ),
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isCFUser
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "list-li-item proportion",
                                            },
                                            [
                                              _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.proportion
                                                  ),
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                }),
                              ]
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            : [_c("div", { staticClass: "empty" }, [_vm._v("暂无数据哦~")])],
          _vm.total
            ? _c(
                "el-pagination",
                {
                  staticClass: "pagination-common",
                  attrs: {
                    "current-page": _vm.page.current,
                    "page-sizes": [15, 20, 50],
                    layout: "total, sizes, prev, pager, next, slot",
                    total: _vm.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                },
                [
                  _c("span", { staticClass: "pagination-text" }, [
                    _c(
                      "span",
                      [
                        _vm._v("前往"),
                        _c("el-input", {
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handlePagerJump.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.pagerJump,
                            callback: function ($$v) {
                              _vm.pagerJump = $$v
                            },
                            expression: "pagerJump",
                          },
                        }),
                        _vm._v("页"),
                      ],
                      1
                    ),
                    _c("span", { on: { click: _vm.handlePagerJump } }, [
                      _vm._v("跳转"),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "talentreuse-top" }, [
      _c(
        "a",
        {
          staticClass: "talentreuse-back",
          attrs: { href: "/Headhunting/#/data" },
        },
        [_c("i", { staticClass: "el-icon-arrow-left" }), _vm._v("返回\n      ")]
      ),
      _c("div", { staticClass: "talentreuse-route" }, [
        _vm._v("\n        工作台\n        "),
        _c("i", { staticClass: "el-icon-arrow-right" }),
        _vm._v("\n        数据\n        "),
        _c("i", { staticClass: "el-icon-arrow-right" }),
        _c("span", { staticClass: "green-write" }, [_vm._v("人才复用")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "list-li viewcandidate" }, [
      _c("span", { staticClass: "list-li-item time" }, [_vm._v("浏览时间")]),
      _c("span", { staticClass: "list-li-item username" }, [_vm._v("浏览人")]),
      _c("span", { staticClass: "list-li-item resume" }, [_vm._v("浏览简历")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "list-li viewcandidate" }, [
      _c("span", { staticClass: "list-li-item time" }, [_vm._v("查看时间")]),
      _c("span", { staticClass: "list-li-item username" }, [_vm._v("查看人")]),
      _c("span", { staticClass: "list-li-item resume" }, [_vm._v("查看简历")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "list-li viewcandidate" }, [
      _c("span", { staticClass: "list-li-item time" }, [_vm._v("查看时间")]),
      _c("span", { staticClass: "list-li-item username" }, [_vm._v("查看人")]),
      _c("span", { staticClass: "list-li-item resume" }, [_vm._v("查看简历")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "list-li viewcandidate" }, [
      _c("span", { staticClass: "list-li-item time" }, [_vm._v("浏览时间")]),
      _c("span", { staticClass: "list-li-item username" }, [
        _vm._v("简历拥有人"),
      ]),
      _c("span", { staticClass: "list-li-item resume" }, [_vm._v("查看简历")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "list-li viewcandidate" }, [
      _c("span", { staticClass: "list-li-item time" }, [_vm._v("查看时间")]),
      _c("span", { staticClass: "list-li-item username" }, [
        _vm._v("简历拥有人"),
      ]),
      _c("span", { staticClass: "list-li-item resume" }, [_vm._v("查看简历")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "list-li viewcandidate" }, [
      _c("span", { staticClass: "list-li-item time" }, [_vm._v("查看时间")]),
      _c("span", { staticClass: "list-li-item username" }, [
        _vm._v("简历拥有人"),
      ]),
      _c("span", { staticClass: "list-li-item resume" }, [_vm._v("查看简历")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }