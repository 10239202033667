<template>
  <div class="talentreuse-container">
    <div class="talentreuse">
      <div class="talentreuse-top">
        <a class="talentreuse-back" href="/Headhunting/#/data">
          <i class="el-icon-arrow-left"></i>返回
        </a>
        <div class="talentreuse-route">
          工作台
          <i class="el-icon-arrow-right"></i>
          数据
          <i class="el-icon-arrow-right"></i>
          <span class="green-write">人才复用</span>
        </div>
      </div>

      <div class="talentreuse-list" v-loading="loading">
        <div class="talentreuse-list-title">
          {{talentReuseTitle}} :
          <span class="green-write">{{data.total ? data.total : 0}}</span>
        </div>
        <template v-if="data.total">
          <ul class="talentreuse-list-ul">
            <template v-if="key == 'ViewCandidates'">
              <!-- 我的简历被浏览 -->
              <template v-if="candidateViewType == 0 && isMyCandidate">
                <li class="list-li viewcandidate">
                  <span class="list-li-item time">浏览时间</span>
                  <span class="list-li-item username">浏览人</span>
                  <span class="list-li-item resume">浏览简历</span>
                </li>
                <li class="list-li viewcandidate" v-for="(item, index) in list" :key="index">
                  <span class="list-li-item time">
                    <span v-text="item.operationTime"></span>
                  </span>
                  <span class="list-li-item username">
                    <span v-text="item.userName" :title="item.userName"></span>
                  </span>
                  <span class="list-li-item resume">
                    <a
                      class="list-li-item-a"
                      target="_blank"
                      v-bind="{ href: '/#/candidateDetail/' + item.candidateId}"
                      :title="item.candidateInfo"
                      v-text="item.candidateInfo"
                      @click="handleCandidate(item)"
                    ></a>
                  </span>
                </li>
              </template>
              <!-- 我的简历电话被查看 -->
              <template v-if="candidateViewType == 1 && isMyCandidate">
                <li class="list-li viewcandidate">
                  <span class="list-li-item time">查看时间</span>
                  <span class="list-li-item username">查看人</span>
                  <span class="list-li-item resume">查看简历</span>
                </li>
                <li class="list-li viewcandidate" v-for="(item, index) in list" :key="index">
                  <span class="list-li-item time">
                    <span v-text="item.operationTime"></span>
                  </span>
                  <span class="list-li-item username">
                    <span v-text="item.userName" :title="item.userName"></span>
                  </span>
                  <span class="list-li-item resume">
                    <a
                      class="list-li-item-a"
                      target="_blank"
                      v-bind="{ href: '/#/candidateDetail/' + item.candidateId}"
                      :title="item.candidateInfo"
                      v-text="item.candidateInfo"
                      @click="handleCandidate(item)"
                    ></a>
                  </span>
                </li>
              </template>
              <!-- 我的简历邮箱被查看 -->
              <template v-if="candidateViewType == 2 && isMyCandidate">
                <li class="list-li viewcandidate">
                  <span class="list-li-item time">查看时间</span>
                  <span class="list-li-item username">查看人</span>
                  <span class="list-li-item resume">查看简历</span>
                </li>
                <li class="list-li viewcandidate" v-for="(item, index) in list" :key="index">
                  <span class="list-li-item time">
                    <span v-text="item.operationTime"></span>
                  </span>
                  <span class="list-li-item username">
                    <span v-text="item.userName" :title="item.userName"></span>
                  </span>
                  <span class="list-li-item resume">
                    <a
                      class="list-li-item-a"
                      target="_blank"
                      v-bind="{ href: '/#/candidateDetail/' + item.candidateId}"
                      :title="item.candidateInfo"
                      v-text="item.candidateInfo"
                      @click="handleCandidate(item)"
                    ></a>
                  </span>
                </li>
              </template>
              <!-- 我浏览同事的简历 -->
              <template v-if="candidateViewType == 0 && !isMyCandidate">
                <li class="list-li viewcandidate">
                  <span class="list-li-item time">浏览时间</span>
                  <span class="list-li-item username">简历拥有人</span>
                  <span class="list-li-item resume">查看简历</span>
                </li>
                <li class="list-li viewcandidate" v-for="(item, index) in list" :key="index">
                  <span class="list-li-item time">
                    <span v-text="item.operationTime"></span>
                  </span>
                  <span class="list-li-item username">
                    <span v-text="item.userName" :title="item.userName"></span>
                  </span>
                  <span class="list-li-item resume">
                    <a
                      class="list-li-item-a"
                      target="_blank"
                      v-bind="{ href: '/#/candidateDetail/' + item.candidateId}"
                      :title="item.candidateInfo"
                      v-text="item.candidateInfo"
                      @click="handleCandidate(item)"
                    ></a>
                  </span>
                </li>
              </template>
              <!-- 我查看同事的简历电话 -->
              <template v-if="candidateViewType == 1 && !isMyCandidate">
                <li class="list-li viewcandidate">
                  <span class="list-li-item time">查看时间</span>
                  <span class="list-li-item username">简历拥有人</span>
                  <span class="list-li-item resume">查看简历</span>
                </li>
                <li class="list-li viewcandidate" v-for="(item, index) in list" :key="index">
                  <span class="list-li-item time">
                    <span v-text="item.operationTime"></span>
                  </span>
                  <span class="list-li-item username">
                    <span v-text="item.userName" :title="item.userName"></span>
                  </span>
                  <span class="list-li-item resume">
                    <a
                      class="list-li-item-a"
                      target="_blank"
                      v-bind="{ href: '/#/candidateDetail/' + item.candidateId}"
                      :title="item.candidateInfo"
                      v-text="item.candidateInfo"
                      @click="handleCandidate(item)"
                    ></a>
                  </span>
                </li>
              </template>
              <!-- 我查看同事的简历邮箱 -->
              <template v-if="candidateViewType == 2 && !isMyCandidate">
                <li class="list-li viewcandidate">
                  <span class="list-li-item time">查看时间</span>
                  <span class="list-li-item username">简历拥有人</span>
                  <span class="list-li-item resume">查看简历</span>
                </li>
                <li class="list-li viewcandidate" v-for="(item, index) in list" :key="index">
                  <span class="list-li-item time">
                    <span v-text="item.operationTime"></span>
                  </span>
                  <span class="list-li-item username">
                    <span v-text="item.userName" :title="item.userName"></span>
                  </span>
                  <span class="list-li-item resume">
                    <a
                      class="list-li-item-a"
                      target="_blank"
                      v-bind="{ href: '/#/candidateDetail/' + item.candidateId}"
                      :title="item.candidateInfo"
                      v-text="item.candidateInfo"
                      @click="handleCandidate(item)"
                    ></a>
                  </span>
                </li>
              </template>
            </template>
            <template v-if="key == 'RecommendCandidates'">
              <!-- 我的简历被推荐 -->
              <template v-if="recommendType == 0 && isMyCandidate">
                <li class="list-li recommendcandidate">
                  <span class="list-li-item time">推荐时间</span>
                  <span class="list-li-item username" :class="{'username-long': !isCFUser}">推荐人</span>
                  <span class="list-li-item resume" :class="{'resume-long': !isCFUser}">推荐人才</span>
                  <span class="list-li-item recommendation" :class="{'recommendation-long': !isCFUser}">推荐公司/职位</span>
                  <span class="list-li-item status">最新状态</span>
                  <span class="list-li-item belongername" v-if="isCFUser">归属权</span>
                  <span class="list-li-item proportion" v-if="isCFUser">我的分成比例</span>
                  <span class="list-li-item realProportion" v-if="isCFUser">实际分成值</span>
                </li>
                <li class="list-li recommendcandidate" v-for="(item, index) in list" :key="index">
                  <span class="list-li-item time">
                    <span v-text="item.operationTime"></span>
                  </span>
                  <span class="list-li-item username" :class="{'username-long': !isCFUser}">
                    <span v-text="item.userName" :title="item.userName"></span>
                  </span>
                  <span class="list-li-item resume" :class="{'resume-long': !isCFUser}">
                    <a
                      class="list-li-item-a"
                      target="_blank"
                      v-bind="{ href: '/#/candidateDetail/' + item.candidateId}"
                      :title="item.candidateInfo"
                      v-text="item.candidateInfo"
                      @click="handleCandidate(item)"
                    ></a>
                  </span>
                  <span class="list-li-item recommendation" :class="{'recommendation-long': !isCFUser}">
                    <span v-text="item.recommendationInfo" :title="item.recommendationInfo"></span>
                  </span>
                  <span class="list-li-item status">
                    <span v-text="item.status"></span>
                  </span>
                  <span class="list-li-item belongername"  v-if="isCFUser">
                    <span v-text="item.belongerName" :title="item.belongerName"></span>
                  </span>
                  <span class="list-li-item proportion"  v-if="isCFUser">
                    <span v-text="item.proportion	"></span>
                  </span>
                   <span class="list-li-item realProportion"  v-if="isCFUser">
                    <span v-text="item.realProportion	"></span>
                  </span>
                </li>
              </template>
              <!-- 我的简历被推荐拿到offer -->
              <template v-if="recommendType == 1 && isMyCandidate">
                <li class="list-li recommendcandidate">
                  <span class="list-li-item time">offer时间</span>
                  <span class="list-li-item username" :class="{'username-long': !isCFUser}">推荐人</span>
                  <span class="list-li-item time">推荐时间</span>
                  <span class="list-li-item resume" :class="{'resume-long': !isCFUser}">offer人才</span>
                  <span class="list-li-item recommendation" :class="{'recommendation-long': !isCFUser}">offer公司/职位</span>
                  <span class="list-li-item belongername" v-if="isCFUser">归属权</span>
                  <span class="list-li-item proportion" v-if="isCFUser">我的分成比例</span>
                  <span class="list-li-item realProportion" v-if="isCFUser">实际分成值</span>
                </li>
                <li class="list-li recommendcandidate" v-for="(item, index) in list" :key="index">
                  <span class="list-li-item time">
                    <span v-text="item.flowTime"></span>
                  </span>
                  <span class="list-li-item username" :class="{'username-long': !isCFUser}">
                    <span v-text="item.userName" :title="item.userName"></span>
                  </span>
                  <span class="list-li-item time">
                    <span v-text="item.operationTime"></span>
                  </span>
                  <span class="list-li-item resume" :class="{'resume-long': !isCFUser}">
                    <a
                      class="list-li-item-a"
                      target="_blank"
                      v-bind="{ href: '/#/candidateDetail/' + item.candidateId}"
                      :title="item.candidateInfo"
                      v-text="item.candidateInfo"
                      @click="handleCandidate(item)"
                    ></a>
                  </span>
                  <span class="list-li-item recommendation" :class="{'recommendation-long': !isCFUser}">
                    <span v-text="item.recommendationInfo" :title="item.recommendationInfo"></span>
                  </span>
                  <span class="list-li-item belongername" v-if="isCFUser">
                    <span v-text="item.belongerName" :title="item.belongerName"></span>
                  </span>
                  <span class="list-li-item proportion" v-if="isCFUser">
                    <span v-text="item.proportion"></span>
                  </span>
                   <span class="list-li-item realProportion"  v-if="isCFUser">
                    <span v-text="item.realProportion	"></span>
                  </span>
                </li>
              </template>
              <!-- 我推荐同事的简历 -->
              <template v-if="recommendType == 0 && !isMyCandidate">
                <li class="list-li recommendcandidate">
                  <span class="list-li-item time">推荐时间</span>
                  <span class="list-li-item username" :class="{'username-long': !isCFUser}">简历拥有人</span>
                  <span class="list-li-item resume" :class="{'resume-long': !isCFUser}">推荐人才</span>
                  <span class="list-li-item recommendation" :class="{'recommendation-long': !isCFUser}">推荐公司/职位</span>
                  <span class="list-li-item status">最新状态</span>
                  <span class="list-li-item belongername" v-if="isCFUser">归属权</span>
                  <span class="list-li-item proportion" v-if="isCFUser">我的分成比例</span>
                </li>
                <li class="list-li recommendcandidate" v-for="(item, index) in list" :key="index">
                  <span class="list-li-item time">
                    <span v-text="item.operationTime"></span>
                  </span>
                  <span class="list-li-item username" :class="{'username-long': !isCFUser}">
                    <span v-text="item.userName" :title="item.userName"></span>
                  </span>
                  <span class="list-li-item resume" :class="{'resume-long': !isCFUser}">
                    <a
                      class="list-li-item-a"
                      target="_blank"
                      v-bind="{ href: '/#/candidateDetail/' + item.candidateId}"
                      :title="item.candidateInfo"
                      v-text="item.candidateInfo"
                      @click="handleCandidate(item)"
                    ></a>
                  </span>
                  <span class="list-li-item recommendation" :class="{'recommendation-long': !isCFUser}">
                    <span v-text="item.recommendationInfo" :title="item.recommendationInfo"></span>
                  </span>
                  <span class="list-li-item status">
                    <span v-text="item.status"></span>
                  </span>
                  <span class="list-li-item belongername" v-if="isCFUser">
                    <span v-text="item.belongerName" :title="item.belongerName"></span>
                  </span>
                  <span class="list-li-item proportion" v-if="isCFUser">
                    <span v-text="item.proportion	"></span>
                  </span>
                </li>
              </template>
              <!-- 我推荐同事的简历拿到offer -->
              <template v-if="recommendType == 1 && !isMyCandidate">
                <li class="list-li recommendcandidate">
                  <span class="list-li-item time">offer时间</span>
                  <span class="list-li-item username" :class="{'username-long': !isCFUser}">简历拥有人</span>
                  <span class="list-li-item time">推荐时间</span>
                  <span class="list-li-item resume" :class="{'resume-long': !isCFUser}">offer人才</span>
                  <span class="list-li-item recommendation" :class="{'recommendation-long': !isCFUser}">offer公司/职位</span>
                  <span class="list-li-item belongername" v-if="isCFUser">归属权</span>
                  <span class="list-li-item proportion" v-if="isCFUser">我的分成比例</span>
                </li>
                <li class="list-li recommendcandidate" v-for="(item, index) in list" :key="index">
                  <span class="list-li-item time">
                    <span v-text="item.flowTime"></span>
                  </span>
                  <span class="list-li-item username" :class="{'username-long': !isCFUser}">
                    <span v-text="item.userName" :title="item.userName"></span>
                  </span>
                  <span class="list-li-item time">
                    <span v-text="item.operationTime"></span>
                  </span>
                  <span class="list-li-item resume" :class="{'resume-long': !isCFUser}">
                    <a
                      class="list-li-item-a"
                      target="_blank"
                      v-bind="{ href: '/#/candidateDetail/' + item.candidateId}"
                      :title="item.candidateInfo"
                      v-text="item.candidateInfo"
                      @click="handleCandidate(item)"
                    ></a>
                  </span>
                  <span class="list-li-item recommendation" :class="{'recommendation-long': !isCFUser}">
                    <span v-text="item.recommendationInfo" :title="item.recommendationInfo"></span>
                  </span>
                  <span class="list-li-item belongername" v-if="isCFUser">
                    <span v-text="item.belongerName" :title="item.belongerName"></span>
                  </span>
                  <span class="list-li-item proportion" v-if="isCFUser">
                    <span v-text="item.proportion"></span>
                  </span>
                </li>
              </template>
            </template>
          </ul>
        </template>
        <template v-else>
          <div class="empty">暂无数据哦~</div>
        </template>

        <el-pagination
          class="pagination-common"
          :current-page="page.current"
          :page-sizes="[15, 20, 50]" 
          layout="total, sizes, prev, pager, next, slot" 
          :total="total"
          v-if="total"
          @size-change="handleSizeChange" 
          @current-change="handleCurrentChange">
          <span class="pagination-text">
            <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
            <span @click="handlePagerJump">跳转</span>
          </span>
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import myService from "#/js/service/myService.js";

const TitleMap = {
  ViewCandidates: {
    0: {
      0: "我的简历被浏览",
      1: "我浏览同事的简历"
    },
    1: {
      0: "我的简历电话被查看",
      1: "我查看同事的简历电话"
    },
    2: {
      0: "我的简历邮箱被查看",
      1: "我查看同事的简历邮箱"
    }
  },
  RecommendCandidates: {
    0: {
      0: "我的简历被推荐",
      1: "我推荐同事的简历"
    },
    1: {
      0: "我的简历被推荐拿到offer",
      1: "我推荐同事的简历拿到offer"
    }
  }
};

export default {
  name: "talentReuse",
  components: {
  },
  data() {
    return {
      loading: false,
      talentReuseTitle: "",
      key: "",
      data: {},
      list: [],
      page: {
        current: 1,
        take: 15
      },
      total: 0,
      pagerJump: 0
    };
  },
  computed: {
    isCFUser() {
      return !!this.$store.state.user.userInfo.isCFUser;
    }
  },
  mounted() {
    this.json = JSON.parse(localStorage.getItem("talentReuseDetailData"));
    this.key = this.json && this.json.key;
    this.candidateViewType =
      this.json && this.json.candidateViewType
        ? this.json.candidateViewType
        : 0;
    this.recommendType =
      this.json && this.json.recommendType ? this.json.recommendType : 0;
    this.isMyCandidate =
      this.json && this.json.isMyCandidate;

    this.json.take = 15;
    switch (this.key) {
      case "ViewCandidates":
        this.talentReuseTitle =
          TitleMap[this.key][this.candidateViewType][
            this.isMyCandidate ? "0" : "1"
          ];
        this.getViewCandidates(this.json);
        break;
      case "RecommendCandidates":
        this.talentReuseTitle =
          TitleMap[this.key][this.recommendType][
            this.isMyCandidate ? "0" : "1"
          ];
        this.getRecommendCandidates(this.json);
        break;
    }
  },
  methods: {
    handleSizeChange(val){
      this.page.current = 1;
      this.page.take = val;
      let offset = this.json;
      offset.start = (this.page.current - 1) * this.page.take;
      offset.take = this.page.take;
      switch (this.key) {
        case "ViewCandidates":
          this.getViewCandidates(offset);
          break;
        case "RecommendCandidates":
          this.getRecommendCandidates(offset);
          break;
      }
    },
    handleCurrentChange(val) {
      this.page.current = val;
      let offset = this.json;
      offset.start = (this.page.current - 1) * this.page.take;
      offset.take = this.page.take;
      switch (this.key) {
        case "ViewCandidates":
          this.getViewCandidates(offset);
          break;
        case "RecommendCandidates":
          this.getRecommendCandidates(offset);
          break;
      }
    },
    handlePagerJump(){
      let currentPager = Number(this.pagerJump),
      currentPageTotal = Math.ceil(this.total/this.page.take);
      if(currentPager > 0 && currentPager <= currentPageTotal){
          this.handleCurrentChange(currentPager)
      }
    },
    // 获取查看简历数据
    getViewCandidates: function(offset) {
      this.loading = true;
      myService
        .getViewCandidates(offset)
        .then(res => {
          this.data = res;
          this.list = this.data.list;
          this.total = this.data.total;
        })
        .catch(err => {
          console.log(err);
        }).finally(() =>{
          this.loading = false;

          document.querySelector(".talentreuse-container").scrollTop = 0;
        });
    },
    // 获取推荐简历数据
    getRecommendCandidates: function(offset) {
      this.loading = true;
      myService
        .getRecommendCandidates(offset)
        .then(res => {
          this.data = res;
          this.list = this.data.list;
          this.total = this.data.total;
          console.log(this.list)
        }).catch(err => {
          console.log(err);
        }).finally(() =>{
          this.loading = false;

          document.querySelector(".talentreuse-container").scrollTop = 0;
        });
    },
    handleCandidate(item){
      lbdTrack.track('viewCandidateDetail',{candidateId:item.candidateId});
    }
  }
};
</script>
<style lang="scss">
@import "~#/css/common";

.talentreuse-container {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}
.talentreuse {
  background-color: #fff;
  padding: 20px 0 38px;
  min-height: calc(100vh - 90px);
  .green-write {
    color: #38bd9d;
  }

  &-top {
    padding: 0 20px;
  }

  &-back {
    font-size: 12px;
    color: #666;
    height: 22px;
    padding: 2px 5px;
    background-color: #eee;

    &:hover {
      color: inherit;
      cursor: pointer;
    }
  }

  &-route {
    display: inline-block;
    margin-left: 20px;
    font-size: 14px;
    line-height: 18px;
  }

  &-list {
    width: 100%;
    margin: 20px auto;
    // padding: 30px 0;
    background-color: #fff;

    &-title {
      padding: 0 20px;
      height: 37px;
      line-height: 37px;
      margin: 25px 0;
      font-size: 28px;
    }

    &-ul {
      width: 100%;
      border: 1px solid #EBEEF5;
      font-size: 14px;
      color: #666;

      .list-li {
        height: 42px;
        display: flex;
        // justify-content: space-around;
        align-items: center;

        // &:nth-of-type(2n) {
        //   background-color: #f8f8f8;
        // }

        border-top: 1px solid #eee;
        &:first-child {
          border-top: none;
          background-color: #F6F8F9;
          .list-li-item {
            color: #666;
            font-weight: bold;
          }
        }

        &-item {
          height: 42px;
          line-height: 42px;
          flex: 1;
          padding: 0 20px;
          border-left: 1px solid #eee;
          display: inline-block;

          &:first-child {
              border-left: none;
          }

          &.time {
            width: 140px;
          }

          &.recommendation {
            width: 163px;
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          &.recommendation-long {
            width: 223px;
          }

          &.status {
            width: 60px;
          }

          &.belongername {
            width: 80px;
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          &.proportion {
            width: 84px;
          }
          &.realProportion {
            width: 84px;
          }

          &-a {
            color: #444;

            &:hover {
              color: #38bc9d;
              cursor: pointer;
            }
          }
        }

        &.viewcandidate > .username {
          width: 135px;
        }

        &.viewcandidate > .resume {
          width: 480px;
        }

        &.recommendcandidate > .username {
          width: 80px;
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &.recommendcandidate > .username-long {
          width: 140px;
        }
        
        &.recommendcandidate > .resume {
          width: 182px;
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &.recommendcandidate > .resume-long {
          width: 302px;
        }
      }
    }

    .pagination-common {
      text-align: right;
      margin: 35px 35px 0 0;
    }

    .empty {
      width: 100%;
      height: 88px;
      margin: 36px auto;
      padding-top: 30px;
      border: 1px solid #d4d4d4;
      color: #666;
      text-align: center;
    }
  }
}
</style>
